import React, { useState } from 'react';
import { OAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from '../firebase';
import './SignInWithApple.css';

function SignInWithApple({ user, setUser }) {

    const handleSignInWithApple = () => {
        const provider = new OAuthProvider('apple.com');
        signInWithPopup(auth, provider)
            .then((result) => {
                setUser(result.user);
            })
            .catch((error) => {
                console.error(error.message);
            });
    };

    return (
        <div>
            <button className='apple-signup-button' onClick={handleSignInWithApple}>Sign in with Apple</button>
        </div>
    );
}

export default SignInWithApple;
