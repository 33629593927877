import './App.css';
import React, { useEffect } from 'react';
import { Analytics } from "@vercel/analytics/react"
import { BrowserRouter } from 'react-router-dom';
import { Link, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import Hard75Page from './pages/Hard75Page/Hard75Page'
import * as amplitude from '@amplitude/analytics-browser';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
import { SpeedInsights } from "@vercel/speed-insights/react"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_key_f,
  authDomain: process.env.REACT_APP_auth_domain_f,
  projectId: process.env.REACT_APP_project_id_f,
  storageBucket: process.env.REACT_APP_storage_bucket_f,
  messagingSenderId: process.env.REACT_APP_messaging_sender_id_f,
  appId: process.env.REACT_APP_app_id_f,
  measurementId: process.env.REACT_APP_measurement_id_f
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

amplitude.init(process.env.REACT_APP_API_key_a, {
  defaultTracking: {
    attribution: true,
    pageViews: true
  },
});

const routes = [
  { path: "/", element: <HomePage /> },
  { path: "/75hard", element: <Hard75Page challengeName={'75 Hard'} /> },
  { path: "/75medium", element: <Hard75Page challengeName={'75 Medium'} /> },
  { path: "/75soft", element: <Hard75Page challengeName={'75 Soft'} /> },
  { path: "/behardone", element: <Hard75Page challengeName={'BeHard One'} /> },
  {path: "/behard50", element: <Hard75Page challengeName={'BeHard 50'} />}
];

function App() {
  useEffect(() => {

    signInAnonymously(auth)
      .then(({ user }) => {
        amplitude.setUserId(user.uid)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>

        <Analytics />
        <SpeedInsights />
      </div>
    </BrowserRouter>
  );
}

export default App;
