// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getRemoteConfig, fetchAndActivate } from "firebase/remote-config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDW3hbAxG9Y0UzKIZSqTX4iNhpC6M6yyGE",
    authDomain: "behard-39e84.firebaseapp.com",
    projectId: "behard-39e84",
    storageBucket: "behard-39e84.appspot.com",
    messagingSenderId: "368749546089",
    appId: "1:368749546089:web:18c4368991a3657cad70f8",
    measurementId: "G-G2HRKHYHPQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore();
export const remoteConfig = getRemoteConfig(app);
// remoteConfig.settings = {
//     minimumFetchIntervalMillis: 3600000,
// };

export { fetchAndActivate };