import React from 'react';
import './HomePage.css';
import * as amplitude from '@amplitude/analytics-browser';


function downloadButtonClicked(type) { 
    const event = {
        event_type: "download_button_clicked",
        event_properties: {
            page: 'Home Page',
            type: type
        }
    };

    amplitude.track(event);
    
    var redirectLink;

    if (type === 'android') {
        redirectLink = "https://play.google.com/store/apps/details?id=com.apps.behard";

    } else if (type === 'ios') {
        redirectLink = "https://apps.apple.com/us/app/75-days-challenge-tough/id6450723685";
    }
    
    window.location.href = redirectLink
}



function HomePage() {
    return (
        <>
            <div className="App">
                <div className="App-main-title">
                    <p>BEHARD</p>
                </div>

                <div className="button-container">
                    {/* App Store Button */}
                    <button onClick={() => downloadButtonClicked('ios')}>
                        <img className="app-store-download-button"
                            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                            alt="Download on the App Store" />
                    </button>

                    {/* Google Play Store Button */}
                    <button onClick={() => downloadButtonClicked('android')}>
                        <img className="play-store-download-button"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                            alt="Get it on Google Play" />
                    </button>
                </div>
            </div>
        </>
    );
}

export default HomePage;